import React from "react";
import { Oval } from "react-loading-icons";

const PopupModal = ({ img }) => {
  const [open, setOpen] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  return (
    <div className="modal fade show" style={{ display: open ? "block" : "none" }} id="modal-02" tabindex="-1">
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content text-center">
          <div className="modal-body">
            <button
              onClick={() => setOpen(false)}
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            {!loading ? <Oval stroke="#06bcee" strokeOpacity={1} speed={1} fillOpacity={1} strokeWidth={2} /> : null}
            <div className="row">
              <div className="col-md-12">
                <figure className="mb-6">
                  <img
                    style={loading ? {} : { display: "none" }}
                    onLoad={() => setLoading(true)}
                    src={`https://drive.google.com/uc?export=view&id=${img}`}
                    alt=""
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupModal;
