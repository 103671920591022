import React, { useState } from "react";
import { ncmtcProfileData } from "../data";

const ProgramAccordion = () => {
  const [activeStep, setActiveStep] = useState([0]);

  const handleAccordionActive = (step) => {
    let temp = activeStep;

    if (activeStep.includes(step)) {
      temp = temp.filter((item) => item !== step);
    } else {
      temp.push(step);
    }

    setActiveStep([...temp]);
  };

  return (
    <div className="accordion accordion-wrapper" id="accordionSimpleExample">
      {ncmtcProfileData.programs.map((step, index) => (
        <div key={index} className="card plain accordion-item">
          <div className="card-header" id="headingSimpleOne">
            <button
              className={`accordion-button ${activeStep.includes(step.id) ? "" : "collapsed"}`}
              onClick={() => handleAccordionActive(step.id)}
              style={{ fontSize: "1rem" }}
            >
              {step.title}
            </button>
          </div>
          <div
            id="collapseSimpleOne"
            className={`accordion-collapse collapse ${activeStep.includes(step.id) ? "show" : ""}`}
          >
            <div className="card-body">
              <div className="row g-6 mb-6">
                <p>{step.description}</p>
                <ul style={{ listStyleType: "number" }} className="icon-list bullet-red">
                  {step.checkList.map((check, i) => (
                    <li key={`${index}0${i}`}>
                      {/* <i className="uil uil-circle"></i> */}
                      <div className="d-flex justify-content-between ">
                        <span className="font-weight-600">{check.title}</span>
                        <a
                          // style={{ width: "20%", margin: "auto" }}
                          target="_blank"
                          href={step.id !== 2 ? `${step.link}${check.title.replace("&", "and")}` : step.link}
                          className="btn btn-primary mb-3 rounded-pill"
                          rel="noreferrer"
                        >
                          {step.linkText}
                        </a>
                      </div>
                      <ul className="unordered-list bullet-primary py-2">
                        {check.children?.map((child, idx) => {
                          return (
                            <li key={`${index}0${i}0${idx}`}>
                              <span
                                style={{ display: "block" }}
                                className="fs-15 font-weight-700 text-uppercase text-bold text-navy py-2"
                              >
                                {child.title}:
                              </span>
                              <span>{child.text}</span>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  ))}
                </ul>
                {index === ncmtcProfileData.programs.length - 1 ? (
                  <a
                    style={{ width: "20%", margin: "auto" }}
                    target="_blank"
                    href={step.link}
                    className="btn btn-primary mb-3 rounded-pill"
                    rel="noreferrer"
                  >
                    {step.linkText}
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProgramAccordion;
