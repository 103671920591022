import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = (props) => {
  const { main, subMain, child, link } = props;
  return (
    <section className="wrapper bg-light" style={{ opacity: 1 }}>
      <div className="sahakari-bg-orange" />
      <div className="sahakari-bg-green" />
      <div className="sahakari-bg-red" />
      <div className="container pt-15 pb-15 pt-md-17 pb-md-15 text-center">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <h1 className="display-1 mb-3 text-dark">{subMain}</h1>
            <nav className="d-inline-block" aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item text-dark">
                  <Link to={link}>{main}</Link>
                </li>
                <li className="breadcrumb-item active">{subMain}</li>
                {child && (
                  <li className="breadcrumb-item active text-dark" aria-current="page">
                    {child}
                  </li>
                )}
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Breadcrumb;
