/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import joinIcon from "../assets/img/icons/lineal/puzzle-2.svg";
import { gapi } from "gapi-script";
import { useGoogleLogin } from "@react-oauth/google";

const EnrollmentForm = () => {
  const SCOPES = "https://www.googleapis.com/auth/documents";

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      console.log(tokenResponse);
      createFile();
    },
    scope: SCOPES,
  });

  const createFile = () => {
    // const accessToken = gapi.auth.getToken().access_token;
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLSdJCRyALbvcZvwtXmD2ceYvfdrmP608593HAY8Zv7haav4jNQ/viewform?vc=0&c=0&w=1&flr=0",
      "_blank"
    );
    // fetch("https://docs.googleapis.com/v1/documents?title=Notes" + getTimeString(), {
    //   method: "POST",
    //   headers: new Headers({ Authorization: "Bearer " + accessToken }),
    // })
    //   .then((res) => {
    //     return res.json();
    //   })
    //   .then((val) => {
    //     console.log({ val });
    //     // window.open("https://docs.google.com/document/d/" + "1w27qvrPTxFv01nn1l8RHQHf9xJ6TdD27", "_blank");
    //   });
  };
  return (
    <section className="wrapper bg-light">
      <div className="container pb-14 pb-md-16 text-center">
        <div className="row">
          <div className="col-md-9 col-lg-7 col-xl-7 mx-auto text-center">
            <img src={joinIcon} className="icon-svg icon-svg-md mb-4" alt="" />
            <h2 className="display-4 mb-3">Join Our Programs</h2>
            <p className="lead fs-lg mb-6 px-xl-10 px-xxl-15">
              Please fill out the online Enrollment form with your information.
            </p>
            <a onClick={() => createFile()} className="btn btn-primary rounded">
              Enrollment Form
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EnrollmentForm;
