import React from "react";
import { ReactSVG } from "react-svg";

export const ContentSection = (props) => {
  const { description, title, icon } = props;

  return (
    <section className="wrapper bg-light">
      <div className="container py-12 py-md-10 text-center">
        <div className="row">
          <div className="col-md-12 col-lg-12 col-xl-12 mx-auto text-centers">
            {icon ? <ReactSVG src={icon} className="icon-svg icon-svg-md mb-4 m-auto" alt="" /> : null}
            <h2 className="display-4 mb-3"> {title} </h2>
            <p className="lead fs-lg mb-6 px-xl-10 px-xxl-15">{description.text}</p>
            {description.points?.length ? (
              <div className="row" style={{ textAlign: "left" }}>
                <div className="col-xl-12">
                  <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                    {description.points.map((pt, index) => {
                      return (
                        <li key={index}>
                          <span>
                            <i className="uil uil-check"></i>
                          </span>
                          <span>{pt}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};
