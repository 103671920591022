import React from "react";
import Breadcrumb from "../common/components/breadcrumb";
import { ContentSection } from "../common/components/contentSection";
import Footer from "../common/components/footer";
import HeaderTwo from "../common/components/header-two";
import Layout from "../common/layout";
import MenuContextProvider from "../context/menu-text";
// import objectiveIcon from "../common/assets/img/icons/lineal/check-list.svg";
import { codeOfConductData } from "../common/data";

const CodeOfConduct = () => {
  console.log(codeOfConductData);
  return (
    <MenuContextProvider>
      <Layout PageTitle="Code of Conduct">
        <HeaderTwo />
        <Breadcrumb link="/" main="Home" subMain="Code of Conduct" />
        <ContentSection title={codeOfConductData.boardMember.title} description={codeOfConductData.boardMember} />
        <ContentSection title={codeOfConductData.members.title} description={codeOfConductData.members} />
        <ContentSection
          //   icon={objectiveIcon}
          //   title={codeOfConductData.manager.title}
          description={codeOfConductData.manager}
        />
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default CodeOfConduct;
