import React from "react";
import Breadcrumb from "../../common/components/breadcrumb";
import Footer from "../../common/components/footer";
import HeaderTwo from "../../common/components/header-two";
import Layout from "../../common/layout";
import MenuContextProvider from "../../context/menu-text";
import UpdateSection from "../../common/components/UpdateSection";

const BODPage = () => {
  return (
    <MenuContextProvider>
      <Layout PageTitle="About Us">
        <HeaderTwo />
        <Breadcrumb link="/about" main="About Us" subMain="Board of Director" />
        <UpdateSection />
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default BODPage;
