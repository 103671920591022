import { Outlet } from "react-router-dom";
import AboutPage from "../page/About";
import AccountCommunityPage from "../page/About/AccountCommunityPage";
import BODPage from "../page/About/BODPage";
import CoreBusinessPage from "../page/About/CoreBusiness";
import GoalPage from "../page/About/Goal";
import InformationOfficerPage from "../page/About/InformationOfficerPage";
import MgmtTeam from "../page/About/MgmtPage";
import MissionPage from "../page/About/Mission";
import ObjectivePage from "../page/About/Objective";
import VisionPage from "../page/About/Vision";
import BlogPage from "../page/BlogPage";
import CareerPage from "../page/CareerPage";
import CodeOfConduct from "../page/CodeofConductPage";
import ContactPage from "../page/ContactPage";
import DownloadPage from "../page/DownloadPage";
import Homepage from "../page/Homepage";
import InvestorRelationPage from "../page/InvestorRelationPage";
import NewsPage from "../page/NewsPage";
import OurStoryPage from "../page/OurStoryPage";
import PrivacyPolicy from "../page/PrivacyPolicy";
import ProjectPage from "../page/ProjectPage";
import ServicesPage from "../page/ServicesPage";
import TermsUsePage from "../page/TermsPage";
import TrainingPage from "../page/TrainingPage";

export const routes = () => {
  return [
    {
      path: "/",
      element: <Outlet />,
      children: [
        {
          path: "/about",
          element: <Outlet />,
          children: [
            { path: "", element: <AboutPage />, index: true },
            { path: "introduction", element: <VisionPage /> },
            // { path: "mission", element: <MissionPage /> },
            // { path: "goal", element: <GoalPage /> },
            { path: "core-business", element: <CoreBusinessPage /> },
            { path: "objectives", element: <ObjectivePage /> },
            { path: "bod", element: <BODPage /> },
            { path: "account_committee", element: <AccountCommunityPage /> },
            { path: "mgmt_team", element: <MgmtTeam /> },
            // { path: "info_officer", element: <InformationOfficerPage /> },
          ],
        },
        { path: "/download", element: <DownloadPage /> },
        { path: "/training", element: <TrainingPage /> },
        { path: "/vacancy", element: <CareerPage /> },
        { path: "/contact", element: <ContactPage /> },
        { path: "/services", element: <ServicesPage /> },
        { path: "/news", element: <NewsPage /> },
        { path: "/careers", element: <CareerPage /> },
        { path: "/privacy-policy", element: <PrivacyPolicy /> },
        { path: "/code-of-conduct", element: <CodeOfConduct /> },
        { path: "/terms-of-use", element: <TermsUsePage /> },
        { path: "/our-story", element: <OurStoryPage /> },
        { path: "/projects", element: <ProjectPage /> },
        { path: "/investor-relations", element: <InvestorRelationPage /> },
        { path: "/story-1", element: <BlogPage /> },
        { path: "/", element: <Homepage /> },
      ],
    },
  ];
};
