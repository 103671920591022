import React, { useEffect } from "react";
import { gapi } from "gapi-script";
import { useLocation } from "react-router-dom";
import EmiCalculator from "../common/components/EmiCalculator";
import Footer from "../common/components/footer";
import HeaderTwo from "../common/components/header-two";
import HomeCarousel from "../common/components/home-carousel";
import NewsSection from "../common/components/news-section";
import PopupModal from "../common/components/popup-modal";
import ServiceOne from "../common/components/service-one";
import SidePopUp from "../common/components/SidePopUp";
import StoryList from "../common/components/story-list";
import Layout from "../common/layout";
import MenuContextProvider from "../context/menu-text";
import FeedbackForm from "../common/components/feedbackForm";

const Homepage = () => {
  const location = useLocation();
  const [imageList, setImageList] = React.useState([]);

  const getData = async () => {
    try {
      // const response = await fetch("https://sheet.best/api/sheets/ba4fa65a-ddba-4850-94ad-21fc1fc8d917");
      // const data = await response.json();
      const data = [
        {
          Image: "https://drive.google.com/file/d/1zdRy-EUUEFEoOohcE2BALMEgUi2QBVID/view?usp=sharing",
        },
        { Image: "https://drive.google.com/file/d/1DIYTFh078x6aKzxQbqMtq_VXmcS0cMnQ/view?usp=sharing" },
      ];
      const temp = data.map((img) => ({ img: img.Image.split("/")[5] }));
      setImageList([...temp]);
    } catch (error) {}
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash);
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <MenuContextProvider>
      <Layout PageTitle="Home">
        {imageList?.map((pop, index) => (
          <PopupModal key={index} img={pop.img} />
        ))}
        <HeaderTwo isHomePage={true} />
        <HomeCarousel />
        <ServiceOne />
        <NewsSection limit={3} />
        <StoryList disperse />
        <SidePopUp />
        <EmiCalculator />
        <FeedbackForm />
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default Homepage;
