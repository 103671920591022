import React, { useState } from "react";
import { Link } from "react-router-dom";

const navbarData = [
  { id: 1, title: "Home", link: "/", hasDropDown: false, children: [] },
  {
    id: 2,
    title: "About Us",
    link: "/about",
    hasDropDown: true,
    children: [
      // { id: 7, title: "Vision", link: "/about/vision" },
      { id: 7, title: "Introduction", link: "/about/introduction" },
      // { id: 8, title: "Mission", link: "/about/mission" },
      // { id: 9, title: "Goal", link: "/about/goal" },
      { id: 10, title: "Core Business", link: "/about/core-business" },
      { id: 11, title: "Objectives/Activites", link: "/about/objectives" },
      { id: 12, title: "Board Of Director", link: "/about/bod" },
      { id: 13, title: "Account Committee", link: "/about/account_committee" },
      { id: 14, title: "Management Team", link: "/about/mgmt_team" },
      // { id: 15, title: "Information Officer", link: "/about/info_officer" },
    ],
  },
  {
    id: 7,
    title: "Services",
    link: "/services",
    hasDropDown: true,
    children: [
      { id: 98, title: "Deposit", link: "/services/#deposit" },
      { id: 97, title: "Remittance", link: "/services/#remittance" },
      { id: 96, title: "Loans", link: "/services/#loans" },
      { id: 95, title: "Others", link: "/services/#others" },
    ],
  },
  { id: 3, title: "News/Activities", link: "/news", hasDropDown: false, children: [] },
  {
    id: 4,
    title: "Investor Relations",
    link: "/investor-relations",
    hasDropDown: true,
    children: [
      { id: 93, title: "Current Programs", link: "/investor-relations/#current" },
      { id: 92, title: "Other Programs", link: "/investor-relations/#other" },
    ],
  },
  { id: 5, title: "Downloads", link: "/download", hasDropDown: false, children: [] },
  {
    id: 6,
    title: "Careers",
    link: "/careers",
    hasDropDown: true,
    children: [
      { id: 99, title: "Training", link: "/training" },
      { id: 94, title: "Vacancy", link: "/vacancy" },
    ],
  },
];

const HeaderTwo = ({ isHomePage = false }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuOpen = () => {
    setMenuOpen(!menuOpen);
  };

  // navbar center-nav transparent position-absolute navbar-expand-lg navbar-dark caret-none
  return (
    <header className="wrapper position-relative">
      <div style={{ height: "81.5px" }} className="navbar-black navbar position-absolute" />
      <nav className="navbar center-nav transparent position-relative navbar-expand-lg navbar-dark ">
        <div className="container flex-lg-row flex-nowrap align-items-center">
          <div className="navbar-brand w-100">
            <Link to="/">
              <img src="/assets/images/logo.jpg" className="responsive-logo" alt="" />
            </Link>
          </div>
          {/* oopen here */}
          <div className={`navbar-collapse offcanvas-nav ${menuOpen ? "open" : ""}`}>
            <div className="offcanvas-header d-lg-none d-xl-none">
              <Link to="/">
                <img src="/assets/images/logo.jpg" height={20} width={200} alt="" />
              </Link>
              <button
                type="button"
                className="btn-close btn-close-white offcanvas-close offcanvas-nav-close"
                aria-label="Close"
                onClick={() => handleMenuOpen()}
              ></button>
            </div>
            <ul className="navbar-nav">
              {navbarData.map((item) => {
                return (
                  <li key={item.id} className="nav-item dropdown">
                    <Link
                      className={`nav-link ${item.hasDropDown ? " dropdown-toggle" : ""}`}
                      data-bs-toggle="dropdown"
                      to={item.link}
                    >
                      {item.title}
                    </Link>
                    {item.children.length ? (
                      <ul className="dropdown-menu">
                        {item.children.map((ch) => {
                          return (
                            <li key={ch.id} className="nav-item">
                              <Link className="dropdown-item" to={ch.link}>
                                {ch.title}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    ) : null}
                    {/* <ul className="dropdown-menu">
                    <li className="dropdown dropdown-submenu dropend">
                      <a className="dropdown-item dropdown-toggle" href="#" data-bs-toggle="dropdown">
                        Dropdown
                      </a>
                      <ul className="dropdown-menu">
                        <li className="dropdown dropdown-submenu dropend">
                          <a className="dropdown-item dropdown-toggle" href="#" data-bs-toggle="dropdown">
                            Dropdown
                          </a>
                          <ul className="dropdown-menu">
                            <li className="nav-item">
                              <a className="dropdown-item" href="#">
                                Action
                              </a>
                            </li>
                            <li className="nav-item">
                              <a className="dropdown-item" href="#">
                                Another Action
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="nav-item">
                          <a className="dropdown-item" href="#">
                            Action
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="dropdown-item" href="#">
                            Another Action
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <a className="dropdown-item" href="#">
                        Another Action
                      </a>
                    </li>
                  </ul> */}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="navbar-other w-100 d-flex ms-auto">
            <ul className="navbar-nav flex-row align-items-center ms-auto" data-sm-skip="true">
              {/* <li className="nav-item dropdown language-select text-uppercase">
                <Link
                  className="nav-link dropdown-item dropdown-toggle"
                  to="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  En
                </Link>
                <ul className="dropdown-menu">
                  <li className="nav-item">
                    <Link className="dropdown-item" to="#">
                      En
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="dropdown-item" to="#">
                      Ne
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="dropdown-item" to="#">
                      Es
                    </Link>
                  </li>
                </ul>
              </li> */}
              <li className="nav-item d-none d-md-block">
                <Link to="/contact" className="btn btn-sm btn-primary rounded-pill">
                  Contact
                </Link>
              </li>
              <li className="nav-item d-lg-none">
                <div className="navbar-hamburger">
                  <button
                    className={`hamburger animate plain ${menuOpen ? "active" : ""}`}
                    onClick={() => handleMenuOpen()}
                    data-toggle="offcanvas-nav"
                  >
                    <span></span>
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default HeaderTwo;
