import React from "react";
import Breadcrumb from "../../common/components/breadcrumb";
import { ContentSection } from "../../common/components/contentSection";
import Footer from "../../common/components/footer";
import HeaderTwo from "../../common/components/header-two";
import Layout from "../../common/layout";
import MenuContextProvider from "../../context/menu-text";
import coreIcon from "../../common/assets/img/icons/lineal/settings.svg";
import { aboutContentData } from "../../common/data";

const CoreBusinessPage = () => {
  return (
    <MenuContextProvider>
      <Layout PageTitle="About Us">
        <HeaderTwo />
        <Breadcrumb link="/about" main="About Us" subMain="Core Business" />
        <ContentSection icon={coreIcon} title="Core Business" description={aboutContentData.coreBusiness} />
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default CoreBusinessPage;
