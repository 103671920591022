import React from "react";
import { Link } from "react-router-dom";
import PersonDetails from "./personDetails";

const Footer = () => {
  const infoOfficerDetails = {
    position: "Information Officer",
    name: "Sani Maiya Maharjan",
    email: "smcl.2078@gmail.com",
    phone: "+977-9820103804",
  };

  return (
    <footer className="bg-secondary text-inverse">
      <div className="container pt-12 pt-md-12 pb-12 pb-md-12">
        <div className="row gy-6 gy-lg-0">
          <div className="col-md-4 col-lg-3">
            <div className="widget">
              <h4 className="widget-title text-white mb-3">Useful Links:</h4>
              <ul className="list-unstyled mb-0">
                <li>
                  <Link to="https://mof.gov.np/">Ministry of Finance</Link>
                </li>
                <li>
                  <Link to="https://www.nrb.org.np/">Nepal Rastra Bank</Link>
                </li>
                <li>
                  <Link to="http://molcpa.gov.np/">
                    Ministry of Land Management, Cooperatives and Poverty Alleviation
                  </Link>
                </li>
                <li>
                  <Link to="http://www.deoc.gov.np/">Department of Cooperative</Link>
                </li>
                <li>
                  <Link to="http://www.ncfnepal.com.np/">National Cooperative Federation of Nepal (NCF/N)</Link>
                </li>
                {/* 
                <li>
                  <Link to="https://www.ica.coop/en">International Cooperative Alliance (ICA)</Link>
                </li>

                <li>
                  <Link to="https://aaccu.coop/">Association of Asian Confederation of Credit Unions (ACCU)</Link>
                </li>

                <li>
                  <Link to="https://www.apraca.org/">
                    Asia-Pacific Rural and Agricultural Credit Association (APRACA)
                  </Link>
                </li>

                <li>
                  <Link to="https://nedac.info/">
                    Network for the Development of Agricultural Cooperative for Asia and the Pacific (NEDAC)
                  </Link>
                </li>

                <li>
                  <Link to="https://www.icba.coop/">International Cooperative Banking Association (ICBA)</Link>
                </li> */}
              </ul>
            </div>
          </div>

          <div className="col-md-4 col-lg-3">
            <div className="widget">
              <h4 className="widget-title text-white mb-3">Get in Touch</h4>
              <address className="pe-xl-15 pe-xxl-17">Samakhusi-26, Kathmandu, Nepal</address>
              <Link to="mailto:smcl.2078@gmail.com">smcl.2078@gmail.com</Link>
              <br /> +977-01-4360764
              <br /> +977-9820103804/03
            </div>
          </div>

          <div className="col-md-4 col-lg-3">
            <div className="widget">
              <h4 className="widget-title text-white mb-3">Learn More</h4>
              <ul className="list-unstyled mb-0">
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
                <li>
                  <Link to="/our-story">Our Story</Link>
                </li>
                <li>
                  <Link to="/projects">Projects</Link>
                </li>
                <li>
                  <Link to="/terms-of-use">Terms of Use</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/code-of-conduct">Code Of Conduct</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-12 col-lg-3">
            <div className="widget">
              <PersonDetails data={infoOfficerDetails} />
              <img className="mb-4" height={30} src="/assets/images/logo4.jpg" alt="" />
              <p className="mb-4">
                © 2021 Sunbarshi. <br className="d-none d-lg-block" />
                All rights reserved.
              </p>
              <nav className="nav social social-white">
                {/* <Link to="/">
                  <i className="uil uil-twitter"></i>
                </Link> */}
                <a href="https://www.facebook.com/smcl2078" target="_blank" rel="noreferrer">
                  <i className="uil uil-facebook-f"></i>
                </a>
                {/* <Link to="/">
                  <i className="uil uil-instagram"></i>
                </Link>
                <Link to="/">
                  <i className="uil uil-youtube"></i>
                </Link> */}
              </nav>
              {/* <h4 className="widget-title text-white mb-3">Our Newsletter</h4>
              <p className="mb-5">Subscribe to our newsletter to get our news & deals delivered to you.</p> */}
              {/* <div className="newsletter-wrapper">
                <div id="mc_embed_signup2">
                  <form>
                    <div id="mc_embed_signup_scroll2" />
                    <div className="mc-field-group input-group form-floating">
                      <input
                        type="email"
                        // value=""
                        name="EMAIL"
                        className="required email form-control"
                        placeholder="Email Address"
                        id="mce-EMAIL2"
                      />
                      <label htmlFor="mce-EMAIL2">Email Address</label>
                      <input
                        type="submit"
                        value="Join"
                        name="subscribe"
                        id="mc-embedded-subscribe2"
                        className="btn btn-primary"
                      />
                    </div>
                  </form>

                  <div id="mce-responses2" className="clear">
                    <div className="response" id="mce-error-response2" style={{ display: "none" }}></div>
                    <div className="response" id="mce-success-response2" style={{ display: "none" }}></div>
                  </div>
                  <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true">
                    <input type="text" name="b_ddc180777a163e0f9f66ee014_4b1bcfa0bc" tabIndex="-1" />
                  </div>
                  <div className="clear"></div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
