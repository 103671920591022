import React from "react";
import Breadcrumb from "../common/components/breadcrumb";
import Footer from "../common/components/footer";
import HeaderTwo from "../common/components/header-two";
import NCMTCProfile from "../common/components/ncmtc_profile";
import Layout from "../common/layout";
import MenuContextProvider from "../context/menu-text";

const TrainingPage = () => {
  return (
    <div>
      <MenuContextProvider>
        <Layout PageTitle="Services">
          <HeaderTwo />
          <Breadcrumb link="/" main="Home" subMain="NCMTC Profile" />
          <NCMTCProfile />
          <Footer />
        </Layout>
      </MenuContextProvider>
    </div>
  );
};

export default TrainingPage;
