import React, { useEffect, useState } from "react";
import { inverstorRelationData } from "../data";
// import img1 from "../assets/img/photos/p1.jpg";
import { useLocation } from "react-router-dom";

const AccordianSection = () => {
  const [activeStep, setActiveStep] = useState([0, 1]);

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  const handleAccordionActive = (step) => {
    let temp = activeStep;

    if (activeStep.includes(step)) {
      temp = temp.filter((item) => item !== step);
    } else {
      temp.push(step);
    }

    setActiveStep([...temp]);
  };

  return (
    <section className="wrapper bg-light">
      <div className="container pb-12 pt-12 pb-md-12 pt-md-12">
        <div className="col-lg-12">
          <h2 className="display-4 mb-3"> {inverstorRelationData.title} </h2>
          <p className="lead fs-md">{inverstorRelationData.description}</p>
        </div>
        <div className="accordion accordion-wrapper" id="accordionIconExample">
          {inverstorRelationData.steps.map((step) => [
            <div key={step.id} className="card accordion-item icon">
              <div className="card-header" id="headingIconOne">
                <button
                  className={`accordion-button ${activeStep.includes(step.id) ? "" : "collapsed"}`}
                  //   className="accordion-button"
                  onClick={() => handleAccordionActive(step.id)}
                  id={step.elementId}
                >
                  <span>
                    <i className={`uil ${step.iconName}`}></i>
                  </span>
                  {step.title}
                </button>
              </div>
              <div
                id="collapseIconOne"
                className={`accordion-collapse collapse ${activeStep.includes(step.id) ? "show" : ""}`}
                // className="accordion-collapse collapse show"
                aria-labelledby="headingIconOne"
                data-bs-parent="#accordionIconExample"
              >
                <div className="card-body">
                  <div className="row g-6">
                    {step.programs.map((curr, index) => {
                      return (
                        <div key={index} className="col-lg-6">
                          <div className="card">
                            {index % 2 === 0 ? (
                              <img
                                className={index % 2 === 0 ? "card-img-top" : "card-img-bottom"}
                                src={curr.image}
                                style={{ height: "350px" }}
                                alt=""
                              />
                            ) : null}
                            <div className="p-8">
                              <h5 className="card-title"> {curr.title} </h5>
                              <p className="card-text">{curr.description}</p>
                            </div>
                            {index % 2 === 0 ? null : (
                              <img
                                style={{ height: "350px" }}
                                className={index % 2 === 0 ? "card-img-top" : "card-img-bottom"}
                                src={curr.image}
                                alt=""
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>,
          ])}

          {/* <div className="card accordion-item icon">
            <div className="card-header" id="headingIconTwo">
              <button
                className="collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#collapseIconTwo"
                aria-expanded="false"
                aria-controls="collapseIconTwo"
              >
                <span>
                  <i className="uil uil-check-circle"></i>
                </span>
                Daily Updates
              </button>
            </div>

            <div
              id="collapseIconTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingIconTwo"
              data-bs-parent="#accordionIconExample"
            >
              <div className="card-body">
                <p>
                  Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit
                  amet risus. Cras mattis consectetur purus sit amet fermentum. Praesent commodo cursus magna, vel.
                </p>
              </div>
            </div>
          </div>

          <div className="card accordion-item icon">
            <div className="card-header" id="headingIconThree">
              <button
                className="collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#collapseIconThree"
                aria-expanded="false"
                aria-controls="collapseIconThree"
              >
                <span>
                  <i className="uil uil-chart-line"></i>
                </span>
                Market Research
              </button>
            </div>

            <div
              id="collapseIconThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingIconThree"
              data-bs-parent="#accordionIconExample"
            >
              <div className="card-body">
                <p>
                  Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit
                  amet risus. Cras mattis consectetur purus sit amet fermentum. Praesent commodo cursus magna, vel.
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default AccordianSection;
