import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { servicesList } from "../data";

const ServiceList = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <div className="container py-14 py-md-16">
      {servicesList.map((service, index) => {
        return (
          <div
            id={service.title.toLowerCase()}
            key={index}
            className="row gx-lg-8 gx-xl-12 gy-10 mb-14 mb-md-18 align-items-center"
          >
            <div className={`col-lg-6 ${index % 2 !== 0 ? "order-lg-2" : ""} position-relative`}>
              <div
                className="shape bg-line leaf rounded-circle rellax w-17 h-17"
                data-rellax-speed="1"
                style={{ top: "-2rem", right: "-0.6rem" }}
              ></div>
              <div
                className="shape bg-pale-violet rounded-circle rellax w-17 h-17"
                data-rellax-speed="1"
                style={{ bottom: "-2rem", left: "-0.4rem" }}
              ></div>
              <figure className="rounded mb-0">
                <div
                  className="icon btn btn-block"
                  style={{
                    fontSize: "15rem",
                    width: "100%",
                    maxWidth: "100%",
                    height: "100%",
                  }}
                >
                  <img src={service.image} style={{ height: "400px" }} alt="" />
                  {/* <i className={`uil ${service.icon} text-primary`}></i> */}
                </div>
              </figure>
            </div>

            <div className="col-lg-6">
              <h3 className="display-4 mb-4">{service.title}</h3>
              <p className="mb-5">{service.description}</p>
              <div className="row gy-3">
                {service.checkList.map((check, cIndex) => {
                  return (
                    <div key={`${index}${cIndex}`} className="col-md-6">
                      <ul className="icon-list bullet-bg bullet-soft-leaf mb-0">
                        <li>
                          <span>
                            <i className="uil uil-check"></i>
                          </span>
                          <span>{check}</span>
                        </li>
                      </ul>
                    </div>
                  );
                })}
              </div>
              <div className="row py-4">
                {service.imageList?.map((img, index) => (
                  <div key={index} className="col">
                    <figure className="px-3 px-md-0 px-xxl-2">
                      <img src={img} alt="" />
                    </figure>
                  </div>
                ))}
              </div>
              <Link to="/services" className="btn btn-soft-aqua rounded-pill mt-6 mb-0">
                More Details
              </Link>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ServiceList;
