import React from "react";
import { ncmtcProfileData } from "../data";
import ProgramAccordion from "./program_accordion";
import visionIcon from "../assets/img/icons/lineal/handshake.svg";
import missionIcon from "../assets/img/icons/lineal/target.svg";
import objectiveIcon from "../assets/img/icons/lineal/check-list.svg";
import goalIcon from "../assets/img/icons/lineal/target-2.svg";
import { ReactSVG } from "react-svg";

const NCMTCProfile = () => {
  return (
    <div>
      <section className="wrapper bg-light">
        <div className="container pt-14 pb-12 pt-md-16 pb-md-14">
          <div className="row gx-lg-8 gx-xl-12 gy-10 mb-lg-2 mb-xl-4 align-items-center">
            <div className="col-lg-7 order-lg-2">
              <div className="row gx-md-5 gy-5">
                <div className="col-md-5 offset-md-1 align-self-end">
                  <div className="card bg-pale-yellow">
                    <div className="card-body">
                      <ReactSVG src={visionIcon} className="icon-svg icon-svg-md text-yellow mb-3" alt="" />
                      <h4>Vision</h4>
                      <p className="mb-0">
                        Our vision is to be most dynamic training service provider & develop profitable professionals.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 align-self-end">
                  <div className="card bg-pale-red">
                    <div className="card-body">
                      <ReactSVG src={missionIcon} className="icon-svg icon-svg-md text-red mb-3" alt="" />
                      <h4>Mission</h4>
                      <p className="mb-0">
                        Our mission is to train & educate individual & corporate to develop necessary skill
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-5">
                  <div className="card bg-pale-leaf">
                    <div className="card-body">
                      <ReactSVG src={objectiveIcon} className="icon-svg icon-svg-md text-leaf mb-3" alt="" />
                      <h4>Objectives</h4>
                      <p className="mb-0">
                        <ul>
                          <li>To develop available Human resource.</li>
                          <li>To create and develop HR as an asset.</li>
                          <li>To help to finding and solving the business issues</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 align-self-start">
                  <div className="card bg-pale-primary">
                    <div className="card-body">
                      <ReactSVG src={goalIcon} className="icon-svg icon-svg-md text-primary mb-3" alt="" />
                      <h4>Action Plans</h4>
                      <p className="mb-0">
                        NCMTC provides different training, workshops, and problem-solving tools and techniques and
                        organizes different seminars/Training /workshops for knowledge sharing which includes: Strategy
                        planning with final road map on hand based on VMOSA.
                        {/* <ul>
                          <li>Budgetaryplanningandimplementation training.</li>
                          <li>Sales and marketing training with tools at low cost.</li>
                          <li>HR planning and different HR Development training.</li>
                          <li>Financial planning and control training.</li>
                          <li>Accounting training with practical aspects including Tax.</li>
                          <li>
                            All rounded training for cooperatives. - Full organizational set ups and SOP preparation
                            service.
                          </li>
                        </ul> */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-5">
              <h2 className="fs-15 text-uppercase text-muted mb-3">What We Do?</h2>
              <h3 className="display-4 mb-5">The service we offer is specifically designed to meet your needs.</h3>
              <p>{ncmtcProfileData.introduction}</p>
              <a href="/about" className="btn btn-navy rounded-pill mt-3">
                More Details
              </a>
            </div>
          </div>
          <h3 className="display-6 py-lg-10">Training Approach of NCMTC</h3>
          <ProgramAccordion />
        </div>
      </section>
    </div>
  );
};

export default NCMTCProfile;
