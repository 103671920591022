import React from "react";
// import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const Images = [
  { id: 1, src: "/assets/images/new/0.jpg" },
  { id: 2, src: "/assets/images/new/2-1.jpg" },
  { id: 3, src: "/assets/images/new/2-2.jpg" },
  { id: 4, src: "/assets/images/new/2-3.jpg" },
  { id: 5, src: "/assets/images/new/2.jpg" },
  // { id: 6, src: "/assets/images/new/5.jpg" },
  { id: 7, src: "/assets/images/new/6.jpg" },
  { id: 8, src: "/assets/images/new/7.jpg" },
  { id: 9, src: "/assets/images/new/8.jpg" },
  { id: 11, src: "/assets/images/new/9.jpg" },
  { id: 12, src: "/assets/images/new/10.jpg" },
  { id: 13, src: "/assets/images/new/11.jpg" },
  { id: 14, src: "/assets/images/new/14.jpg" },
  { id: 15, src: "/assets/images/new/33.jpg" },
  { id: 16, src: "/assets/images/new/65.jpg" },
  { id: 17, src: "/assets/images/new/IMG_5588.jpg" },
  { id: 18, src: "/assets/images/new/IMG_5711.jpg" },
  { id: 19, src: "/assets/images/new/IMG_5730.jpg" },
];

const HomeCarousel = () => {
  return (
    <div className="wrapper bg-dark position-relative">
      <Swiper
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        watchOverflow={true}
        loop={true}
        loopFillGroupWithBlank={true}
        navigation={true}
        slidesPerView={1}
        modules={[Navigation, Autoplay]}
        className=""
      >
        {Images.map((img, index) => {
          return (
            <SwiperSlide key={img.id}>
              <img src={img.src} alt="" height="100%" width="100%" style={{ objectFit: "cover", height: "90vh" }} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default HomeCarousel;
