export const accordionData = [
  {
    index: 1,
    title: "Application for Periodic Deposit",
    description: "Application for Periodic Deposit",
  },
  {
    index: 2,
    title: "Top-Notch Support",
    description:
      "Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, utfermentum massa justo sit amet risus. Cras mattis consectetur purus sit ametfermentum. Praesent commodo cursus magna, vel scelerisque nisl consectetur et.Cum sociis natoque penatibus et magnis dis parturient montes.",
  },
  {
    index: 3,
    title: "Header and Slider Options",
    description:
      "Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, utfermentum massa justo sit amet risus. Cras mattis consectetur purus sit ametfermentum. Praesent commodo cursus magna, vel scelerisque nisl consectetur et.Cum sociis natoque penatibus et magnis dis parturient montes.",
  },
];

export const loanRates = [
  {
    id: 1,
    name: "Agriculture Loan",
    rate: "16.00",
    created_id: 1,
    updated_id: 1,
    created_at: "2020-10-19 02:49:14",
    updated_at: "2022-02-21 07:37:53",
  },
  {
    id: 2,
    name: "Beehive Industry Loan",
    rate: "16.00",
    created_id: 1,
    updated_id: 1,
    created_at: "2020-10-19 02:49:33",
    updated_at: "2022-02-21 07:38:04",
  },
  {
    id: 3,
    name: "Business Loan",
    rate: "16.00",
    created_id: 1,
    updated_id: 1,
    created_at: "2020-10-19 02:49:50",
    updated_at: "2022-02-21 07:38:29",
  },
  {
    id: 4,
    name: "Career Loan",
    rate: "16.00",
    created_id: 1,
    updated_id: 1,
    created_at: "2020-10-19 02:50:04",
    updated_at: "2022-02-21 07:38:41",
  },
  // {
  //   id: 5,
  //   name: "Education Loan",
  //   rate: "16.00",
  //   created_id: 1,
  //   updated_id: 1,
  //   created_at: "2020-10-19 02:50:21",
  //   updated_at: "2022-04-18 07:54:57",
  // },
  {
    id: 6,
    name: "Emergency Loan",
    rate: "16.00",
    created_id: 1,
    updated_id: 1,
    created_at: "2020-10-19 02:50:37",
    updated_at: "2020-12-16 10:19:23",
  },
  {
    id: 7,
    name: "Foreign Employment loan",
    rate: "16.00",
    created_id: 1,
    updated_id: 1,
    created_at: "2020-10-19 02:50:55",
    updated_at: "2022-04-18 07:55:09",
  },
  {
    id: 8,
    name: "Hire Purchase Loan",
    rate: "16.00",
    created_id: 1,
    updated_id: 1,
    created_at: "2020-10-19 02:51:10",
    updated_at: "2022-04-18 07:55:21",
  },
  // {
  //   id: 9,
  //   name: "Home Loan",
  //   rate: "16.00",
  //   created_id: 1,
  //   updated_id: 1,
  //   created_at: "2020-10-19 02:51:24",
  //   updated_at: "2022-04-18 07:56:41",
  // },
  {
    id: 10,
    name: "Income Generating Loan",
    rate: "16.00",
    created_id: 1,
    updated_id: 1,
    created_at: "2020-10-19 02:51:44",
    updated_at: "2022-02-21 07:40:45",
  },
  {
    id: 11,
    name: "Industry Loan",
    rate: "16.00",
    created_id: 1,
    updated_id: 1,
    created_at: "2020-10-19 02:51:58",
    updated_at: "2022-02-21 07:41:04",
  },
  // {
  //   id: 12,
  //   name: "Inter Lending Loan",
  //   rate: "16.00",
  //   created_id: 1,
  //   updated_id: 1,
  //   created_at: "2020-10-19 02:52:17",
  //   updated_at: "2022-02-21 07:41:38",
  // },
  // {
  //   id: 13,
  //   name: "Land Purchase loan",
  //   rate: "16.00",
  //   created_id: 1,
  //   updated_id: 1,
  //   created_at: "2020-10-19 02:52:36",
  //   updated_at: "2022-04-18 07:56:16",
  // },
  {
    id: 14,
    name: "Livestock Loan",
    rate: "16.00",
    created_id: 1,
    updated_id: 1,
    created_at: "2020-10-19 02:52:54",
    updated_at: "2022-02-21 07:42:43",
  },
  {
    id: 15,
    name: "Poultry Farm Loan",
    rate: "16.00",
    created_id: 1,
    updated_id: 1,
    created_at: "2020-10-19 02:53:08",
    updated_at: "2022-02-21 07:43:03",
  },
  // {
  //   id: 16,
  //   name: "Tallering Loan",
  //   rate: "16.00",
  //   created_id: 1,
  //   updated_id: 1,
  //   created_at: "2020-10-19 02:53:24",
  //   updated_at: "2022-02-21 07:43:38",
  // },
  {
    id: 17,
    name: "Professional Loan",
    rate: "16.00",
    created_id: 1,
    updated_id: 1,
    created_at: "2020-10-19 02:53:38",
    updated_at: "2022-02-21 07:43:53",
  },
];

export const contentIcons = {
  vision: "../assets/img/icons/lineal/handshake.svg",
};

export const aboutContentData = {
  vision: { text: "A cooperative with a responsible and sustainable potential for members and society." },
  mission: {
    text: "To establish the organization as an effective servant of society through entrepreneurship by fulfilling the financial needs of the members by protecting the money and investment of the members.",
  },
  goal: {
    text: `To support the success of the "Happy Nepali, Prosperous Nepal" policy taken by the country by fulfilling the livelihood needs and objectives of the members by embracing the basic principles of cooperatives`,
  },
  coreBusiness: {
    text: `SMCL is open to any activity that helps promote the standard of living and ensure safe financial freedom for its members. It will help make proper utilization of capital and provide a significant contribution to the social and economic development of its members through technical knowledge, skills, and training`,
  },
  objective: {
    text: `For the economic, social, and cultural development of the members, the organization will have the following objectives:`,
    points: [
      "To develop the habit of regular saving by awakening the sense of frugality in the members involved in the organization.",
      "To contribute to the building of a self-reliant and independent society by enhancing the individual and collective capacity of the members.",
      "Identifying the primary needs and income earning capacity of members through involving them in productive, income generating, skill generating areas to increase the use of scattered national capital by promoting investment.",
      "To demonstrate the importance of cooperation in the community by conducting service programs that provide institutional support in various areas of the daily life of the members.",
      "Developing mutual harmony and fraternity among the members, supporting the building of a community-based holistic human society, and creating investment opportunities in the productive sector.",
      "To create awareness of self-reliance, cooperation, and economy among the members and enhance the habit of saving.",
      "To raise the economic and social living standards of the members.",
      "Taking the cooperative movement forward by promoting cooperative education and participating in the country's cooperative movement.",
      "Conducting cooperative businesses for the promotion of collective interest.",
      "Managing the market of various consumables, collecting and selling the products.",
      "To provide loans to members at reasonable interest rates for productive, income-generating, financial purposes and cooperation.",
      "Establishing a group of women entrepreneurs and conducting a program of entrepreneurship-oriented training.",
    ],
  },
  activities: {
    text: "The organization shall undertake the following activities to achieve the above-stated objectives:",
    points: [
      "Improve the overall welfare of the members, conducting programs such as production and agriculture approved by them",
      "Collection of savings as per approved policy from members",
      "Loan investment to members as per approved policy",
      "Sale of Shares of the organization as per Section 49 of the Co-operative Act",
      "Make collection from other organizations under the Cooperative regulations",
      "Conducting awareness-raising activities for cooperative members Provide vocational training to the members by providing opportunities for skill training",
      "Disbursing loans individually and collectively in various productive sectors within the ambit of co-operative rules and regulations.",
      "Providing service to the members in various tasks needed in their daily life with or without minimum service charges.",
      "Working to increase access to business knowledge and opportunities by providing information to members.",
    ],
  },
};

export const inverstorRelationData = {
  title: "FINANCIAL POSITION AND PLAN",
  description:
    "Share Capital: At present, the share capital of this organization has been maintained at NPR 30,000,000 (three crores) and under the additional share investment program, the paid-up capital by the financial year 2079/80 will be NPR 100,000,000 (ten crores) has been the policy of the organization.",
  steps: [
    {
      id: 0,
      title: "Current Running Programs",
      elementId: "current",
      iconName: "uil-check-circle",
      programs: [
        {
          title: "Women Entrepreneur Promotion",
          image: "/assets/images/programs/image-042.jpg",
          description:
            "Economic equality significantly empowers women. Having some business or work that provides income means that women can be a more effective force, a more equal force. However , Nepali women entrepreneurs still face the brunt of economic inequality. To address this unjust situation, SMCL will promote the development of women's entrepreneurship, in the form of small and medium- sized enterprises. For this SMCL will provide women with business and management training, leadership development opportunities as well as access to market and access to finance.",
        },
        {
          title: "National Cooperative & Management Training",
          image: "/assets/images/programs/image-043.png",
          description:
            "Through training activities related to management, information handling, and service delivery, SMCL plans to enhance the capacity of the human resources associated with the cooperative sector of the country. Among others, this will familiarize interested individuals with knowledge of cooperative, principles, and scope of cooperative in economic development, and provide insights about legal structure, governance, modalities, and various aspect of cooperative.",
        },
        {
          title: "Compliance Consultancy Service",
          image: "/assets/images/programs/image-064.jpg",
          description:
            "Usually, individuals/groups face challenges in connection with the registration of their new business or organization. This mainly involves compliance related to Online Company Registration (OCR), Inland Revenue Department (IRD), Department of Co-operative Under GON Ministry of Land Management, Co-operative & Poverty Alleviation, State Level and Local Level, Nepal Rastra Bank (NRB), and many more. Realizing this context, SMCL will shortly start providing direct services allowing our members to be confident in the knowledge and the documents to be maintained that their ventures/business is statutory compliant.",
        },
        {
          title: "Digital Products and Digital Marketing",
          image: "/assets/images/programs/image-044.jpg",
          description:
            "Digital products encompass all of the products that use electricity and/or the internet whereas digital marketing is a form of marketing that exists online. In today’s world, it is through digital marketing many businesses are promoting and selling their products/services in front of prospects and customers viz-a-viz to reach the right people in the right place at the right time. SMCL has a pool of experts and expertise on platform plans to help individuals/organizations/businesses make digital product as well as do digital marketing.",
        },
      ],
    },
    {
      id: 1,
      title: "Other Programs",
      iconName: "uil-bars",
      elementId: "other",
      programs: [
        {
          title: "Micro Industry Entrepreneur Promotion",
          image: "/assets/images/programs/image-032.jpg",
          description: `In Nepal, inadequate income-generating opportunities have been a major cause of widespread poverty and the outflow of productive workforce abroad. With aims to cater to the needs of groups and individuals, SMCL will support the promotion of micro industry with several business development services, such as opportunities to develop personal qualities/skills; access to market; access to finance; improved technologies; and help create a conducive business environment. SMCL will provide skill and business training and other support, especially for women and disadvantaged people to set up micro-enterprises.`,
        },
        {
          title: "Argo-based Entrepreneur Promotion",
          image: "/assets/images/programs/image-041.jpg",
          description: `Agro-based businesses offer a great deal of promise to strengthen Nepal's economy, but this potential must be fulfilled by giving them the technical and financial support they require. Realizing this need, SMCL will support youth and small farmers to improve their livelihoods and increase incomes by helping them in the financing, development, and operations of agro-based enterprises. Through the Argo-based Entrepreneur Promotion, SMCL aims of shifting from current subsistence agricultural production to value-adding rural enterprises development that can help people improve their income stream.`,
        },
        {
          title: "Information System Advisor (ISA)",
          image: "/assets/images/programs/image-053.jpg",
          description: `In today’s digitalized world, information safety has become increasingly important. An ISA provides the required support for proper and safe communication between individuals and groups within and beyond organizations, alerting the client's key contact to the information threat and possible misuse, analyzing any anomaly within their network, and ensuring to have the data and context to analyses information related incidents that might have arisen. SMCL plans to provide this important service to the needed business houses/organizations based on their demands.`,
        },
        {
          title: "Travels & Tours",
          image: "/assets/images/programs/image-054.jpg",
          description:
            "Nepali tour operators and travel agents face different challenges daily that mainly include travel marketing, infrastructure issues, and the security of travelers. The travel industry is volatile. SMCL want to understand a such variety of challenges and provide them with applicable solutions. SMCL envisions providing one-stop travel and tour services by meeting the demand of travelers of all categories. This shall be run by travel experts who specialize in Nepal tour operators.",
        },
        {
          title: "Printing Press & Furnishing",
          image: "/assets/images/programs/image-055.jpg",
          description: `Today, innovation in the profession, innovative materials, and knowledge of innovative production technologies that feed creative thinking have become ever important, and for this advanced printing and furnishing is a need of time. Given the interest of cooperative members, SMCL plan to support the operation of the printing press and furnishing jobs with all kinds of Graphic Design work, Screen Print, and press printing jobs. In this sector, SMCL will train and support interested individuals to run printing and furnishing works and develop experimental techniques that lead to creativity.`,
        },
        {
          title: "Hospitality Management",
          image: "/assets/images/programs/image-056.jpg",
          description: `In Nepal, the hospitality industry is important for a wide range of reasons. It is responsible for a sizeable chunk of countries’ GDP, and this percentage is growing with time. A large section of our population is involved in this sector and many communities would suffer if they were not supported by the money that comes in from this area. Realizing this situation SMCL plans to invest in hospitality management work which not just includes customer -facing jobs, but also management roles, catering roles, event planning roles, front-of-house roles, and more.`,
        },
        {
          title: "Senior Citizen, Women and Disable Support",
          image: "/assets/images/programs/image-063.jpg",
          description: `The number of older people, women and the disabled with unmet care and support needs is increasing substantially due to the challenges facing the formal and informal care system in Nepal. Addressing these unmet needs is becoming one of the urgent  health priorities. SMCL with work with like-minded members and other interested stakeholders towards finding and providing effective solutions to address some of these needs. SMCL plans to work forthe physical, social, and psychological well-being of these groups. Protecting girls and women by providing training, education, counseling, medical care, small business loans, and other programs that empower women and girls will also be prioritized.`,
        },
        {
          title: "Online Health Services",
          image: "/assets/images/programs/image-065.jpg",
          description: `Online health services have become a growing trend globally, especially in places with high population density. In an unexpected turn of events, the recent Covid-19 pandemic has dramatically accelerated its popularity as people are finding it increasingly difficult to step out of their homes without risking infection. In major cities of Nepal, like Kathmandu, it has become more of a necessity than an option. Online healthcare is quite effective for primary and intermediate healthcare difficulties like providing diagnosis and treatment for common medical ailments like fever, the flu, injured limbs, and toothaches. Considering this reality, SMCL plan to operate a range of online health services which will include telephone calls, video conferences, streaming media, prerecorded health educational materials, and more.`,
        },
        {
          title: "Social Welfare",
          image: "/assets/images/programs/image-072.jpg",
          description: `According to the principle, a society can thrive only when it provides safeguards for those who face risks to their well-being. Ideally, it is the government that has a responsibility to protect vulnerable populations, but it is also the responsibility of the informed individuals and institutions to help those in need. Having this realization, SMCL plans to initiate a separate scheme of Social Welfare Fund to assist families living in poverty by providing medical care, public health services, assisting with household needs, and assisting social adjustments, among other things. For this SMCL will work in close coordination with Social Welfare Council and other like-minded institutions/individuals.`,
        },
        {
          title: "Domestic Remittance",
          image: "/assets/images/programs/image-073.jpg",
          description: `Domestic remittance allows customers to send and receive money to and from any part of the country. As SMCL is already providing various services for online money transfers, the addition of a domestic remittance service will further expand our strength cum coverage, and we are committed to making it the most secure and reliable online service. One can send or receive the money within a minute to/from other people not just within Nepal but from around the globe. One will pay the lowest fees in the market for such a money transfer.`,
        },
        {
          title: "School/Colleges",
          image: "/assets/images/programs/image-074.jpg",
          description: `Quality education has been proven to be of benefit, not only to individuals but also to societies. It must also be affordable. There is a need for schools and colleges in Nepal that teaches life skills not just theoretical education and books, the place where you are learning, earning money, doing actual work, and being useful. SMCL plans to open and run such educational institutions which provide quality education, life/practical skills training and at the same time are affordable. In the short run, SMCL will operate online subjective classes, skill development training, and preparation classes for students to induce a new generation with skill in their hands, and creativity in their minds.`,
        },
      ],
    },
  ],
};

export const servicesList = [
  {
    title: "Deposit",
    icon: "uil-money-bill",
    image: "/assets/images/IMG_0106.jpg",
    description:
      "Sunbarshi Cooperative offers a wide range of deposit products that caters to your requirement. In order to expand community participation and to extend the program and the scope of the institute, it has been conducting saving and credit transaction successfully since its incorporation.",
    checkList: ["Daily saving", "Monthly Saving", "Education saving", "Optional Saving"],
    imageList: ["/assets/images/remit/deposit_1.png", "/assets/images/remit/deposit_2.png"],
  },
  {
    title: "Remittance",
    icon: "uil-usd-circle",
    image: "/assets/images/remittance.webp",
    description:
      "We provide various services for online money transfers. Our service is the best known, most secure and reliable online service where you can send or receive money within minute to/from other people around the globe by paying reasonable fees in the market for your transfer. Recipients do not require a bank account to receive funds.",
    checkList: [
      "Prabhu Remit",
      "Himal Remit",
      "Western union Remit",
      "E-sewa Remit",
      "EasyLink remit",
      "Samsara Remit",
    ],
    imageList: [
      // "/assets/images/remit/e-sewa.png",
      // "/assets/images/remit/easylink.png",
      "/assets/images/remit/samsara.png",
      "/assets/images/remit/client2.png",
      "/assets/images/remit/prabhu-remit.webp",
    ],
  },
  {
    title: "Loans",
    image: "/assets/images/foreign-investment.jpeg",
    icon: "uil-invoice",
    description:
      "We provide wide range of suitable loan products to our memebers ranging from emergency personal loan to various loans on productive sectors.",
    checkList: [
      "Agriculture Loan",
      "Business Loan",
      "Career Loan",
      "Women Entrepreneurship Loan",
      "Micro Industry Loan",
      "Beehive Industry Loan",
      "Emergency Loan",
      "Foreign Employment loan",
      "Hire Purchase Loan",
      "Income Generating Loan",
      "Industry Loan",
      "Livestock Loan",
      "Poultry Farm Loan",
      "Professional Loan",
    ],
    imageList: [],
  },
  {
    title: "Others",
    icon: "uil-star",
    image: "/assets/images/icons/settings-3.svg",
    description:
      "Various programs are run by the co-operative time to time to promote health, education and community welfare for its members.",
    // "Engaged in promoting the use of compost gas as an alternative energy in order to mitigate environmental destruction and pollution.",
    checkList: [
      "Engaged in providing certain amount as relief loans to its members or their family members in case of sudden disaster or accident.",
      // "In cooperation with various consumer shops, discount cards are being provided to its members to save them from the rising inflation.",
      "Continuously engaged in providing cooperative education to the members living in the premises of the organization.",
      "Continuously engaged in promoting community welfare to the members living in the premises of the organization.",
      "There is also a Member's Health Fund being established by the organization to provide support for a healthy livelihood of its member",
      // "There is also a Member’s Relief Fund programme being run by the organization in the event of the death of its members. Under this, a deceased member’s family is provided a sum of Rs. 10,000 as expenses for posthumous rites.",
    ],
    imageList: [],
  },
];

export const client_id = "861958054388-scr68a0766nqasjr5ulvuaoq75umetjo.apps.googleusercontent.com";
export const client_secret = "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";
export const api_key = "AIzaSyDWrrQPk18Kqo0AtlszlXsjA1JQW7zlG3I";

export const codeOfConductData = {
  manager: {
    text: "Code of conduct to be followed by the managerial side (Employees/Staff)",
    // icon: "uil-star",
    // image: "/assets/images/icons/settings-3.svg",
    // text: "Code of conduct to be followed by the managerial side (Employees/Staff)",
    points: [
      "Any action should not be taken for violation of cooperative values, beliefs, principles,acts, rules, by-laws, regulations, policies, decisions, agreements, ideals,",
      "General meetings, committee / sub-committee decisions should be followed,",
      "Not to be involved in any activity that directly or indirectly causes damage, loss, or defamation of the institution,",
      "Should act without discrimination based on party politics, religion, caste, class, color,etc.,",
      "Confidentiality of the organization should not be breached,",
      "Not to accept or give gifts, donations, offerings, bribes, contributions, etc. in the course of work,",
      "Respecting the self-esteem and ideals of organizations and other stakeholders are treated fairly and equally,",
      "Not to file candidacy in the elections of the institution as well as do campaigning in the elections or any activity to influence,",
      "Without the written permission of the authorized official of the institution, any employee should not give the property of the institution to anyone or should not be taken from anyone,",
      "Should not use the status of the institution to fulfill their personal or party-political interests.",
      "Not to accept pressure from anyone or create pressure on anyone for any illegal activity,",
      "Not to make any kind of condition, agreement, contract, lease, or plan with the institution for personal gain, and not to operate any business, contract lease, etc. of a competitive nature, and",
      "Giving preference to the consumption of goods and services produced by the institution and encouraging to do business with the institution.",
    ],
  },
  boardMember: {
    title: "COOPERATIVE CODE OF CONDUCT",
    subHeading:
      "SMCL is fully committed to the ‘Cooperative Code of Conduct -2064’ passed by the Division Cooperative (Coop Division) which is the apex body of cooperatives of all types and levels in Nepal.",
    text: "Code of conduct to be followed by members holding office responsibilities (Board members):",
    points: [
      "Compulsory compliance with cooperative values, beliefs, principles and cooperative acts, laws, by-laws, regulations, decisions and agreements,",
      "To act without discrimination based on party politics, religion, caste, class, gender, caste, language, etc.,",
      "No kind of gift, donation, endowment, bribe, donation, etc. shall be accepted or given in connection with the business transactions of the organization,",
      "Respecting the self-esteem and ideals of organizations and other stakeholders should be treated kindly based on equality and impartiality.",
      "Should not be bribed, pressured, or accepted to do things against the rules,",
      "To fulfill one's responsibilities honestly and on time, and to assist related parties/individuals/groups,",
      "Not to take personal advantage or fulfill party political interests by misusing their official responsibility,",
      "Work should be carried out by formulating work plans following the objectives of the organization.",
      "Knowingly, directly or indirectly should not be involved in any activity that is contrary to the ideals and interests of the association/institution,",
      "Should not take any kind of contract with the association/institution, must pay the advance, loan/installment on time, and not engage in embezzlement of any kind,",
      "Doing regular business with the institution and priority in using goods and services produced by the institution as far as possible",
      "No personal business or transaction should be conducted that is competitive and detrimental to the institution,",
      "Striving to be a role model in society social behavior to be carried out according to cultural and family customs should be made polite, normal, and frugal,",
      "Not to enter into any contract, lease, or scheme with any kind of condition, agreement, contract with the institution for personal gain,",
      "Cooperative Federation of Nepal, Central Unions, and National Co-operative Bank's board members shall not take personal loans from the institutions to which they belong,",
      "The members who bear the official responsibility should not be enlisted among debtors who do not pay their debts in any bank, financial institution, and cooperative association/organization.",
    ],
  },
  members: {
    text: "Code of conduct to be followed by members:",
    points: [
      "To fully comply with the values, beliefs, principles, and cooperative act, laws, rules, regulations, policy decisions,",
      "To actively participate in the general meeting and other programs of the institution,",
      "Conducting regular transactions in the institution and participating in the business transactions of the institution according to one’s capacity,",
      "Not to take illegitimate advantage by using the status of the institution,",
      "Advances, loans, installments to be paid to the institution, and payment of the amount agreed to be submitted should be done within the specified time.",
      "There should be no discrimination based on religion, caste, class, gender, color, language, party politics, etc., and treat every person with humility and respect,",
      "Not to engage in any activity that is directly or indirectly contrary to the objectives and interests of the institution,",
      "To provide support according to the needs and decisions in the social development works conducted by the institution, and",
      "Getting into the habit of using the services and products provided by the institution.",
    ],
  },
};

export const ncmtcProfileData = {
  introduction:
    "National Co-operative and Management Training Centre (NCMTC) has been established by the combo team of expert professional and Academicians of Nepal to fulfill the academic and professional gap in professional Nepalese business market. This is the outcome of the minds which observed the essential requirements of real professionalism and all rounded business elements. The team of Sunbarshi Multipurpose cooperative established this wing as its Multipurpose objective to full fill the need of training and knowledge sharing platform which is located at Samakhushi, Kathmandu, Nepal. NCMTC aims to provide an action plan practical based trainings approach.",
  programs: [
    {
      id: 0,
      title: "Counselling/Awareness:",
      link: "https://docs.google.com/forms/d/e/1FAIpQLSdPEyZmIsnF15KKXb2pWhC8BUcnN7cyX6KAVhsbNZH2Gj_QyQ/viewform?usp=pp_url&entry.1163862712=",
      linkText: "Interested In",
      description:
        "Under counselling/awareness, participants are made aware of the subjects matters of the interest of participants, in a counselling session provided at free of cost to participants. They are provided with basic knowledge related to the subject matter by experts and coaches. Some of the topics under which counselling/awareness programs are provided by us are:",
      checkList: [
        { title: "Co-operative Awareness", link: "", children: [] },
        { title: "Motivation & Career Counselling", children: [] },
        { title: "Digital Financial Service Awareness", children: [] },
        { title: "Mediation/Art of Living", children: [] },
        { title: "Mediation/Art of Living", children: [] },
        { title: "Positive thinking & Learning mechanism", children: [] },
        { title: "Financial Investment Mechanism", children: [] },
        { title: "Digital Currency & Crypto Currency", children: [] },
        { title: "Importance of body language", children: [] },
        { title: "Stress Management", children: [] },
        { title: "Interview Facing Technique", children: [] },
      ],
    },
    {
      id: 1,
      title: "Seminar",
      link: "https://docs.google.com/forms/d/e/1FAIpQLSdPEyZmIsnF15KKXb2pWhC8BUcnN7cyX6KAVhsbNZH2Gj_QyQ/viewform?usp=pp_url&entry.1163862712=",
      linkText: "Interested In",
      description:
        "Under seminar, participants are taught about subject matter by expertise of related field. A group discussion, interaction, debate and inquiry will be prioritized to clear doubts of the participants regarding the taught subject matter so that participant could learn with greater interest. Some of the topics under which seminars are provided by us are:",
      checkList: [
        { title: "Basics of Share market", children: [] },
        { title: "Resume Writing", children: [] },
        { title: "Startup Idea for Youth", children: [] },
        { title: "Personality development & Interpersonal Skills", children: [] },
        { title: "Entrepreneurship", children: [] },
        { title: "Information technology prospects in Nepal", children: [] },
        { title: "Foreign Direct Investment", children: [] },
        { title: "Research Methodology", children: [] },
        { title: "Digital Marketing", children: [] },
      ],
    },
    {
      id: 2,
      title: "Practically Based Technical Trainings",
      link: "https://docs.google.com/forms/d/e/1FAIpQLSdJCRyALbvcZvwtXmD2ceYvfdrmP608593HAY8Zv7haav4jNQ/viewform?vc=0&c=0&w=1&flr=0",
      linkText: "Apply Now",
      description:
        "Under technical trainings, participants are trained on various subject matters by the expertise of related field. The technical trainings are focused on providing practical knowledge to the participant to boost their competence on the related subject. Some of the topic under which technical trainings are provided by us are:",
      checkList: [
        {
          title: "Strategy Planning and Budgeting (SPB) for Co-Operative Five Days’ Workshop",
          text: "",
          children: [
            {
              title: "Introduction",
              text: "SPB for cooperative is an essential course designed to educate the cooperatives the importance of planning & budgeting for those plans in order to accomplish its objectives in an effective & efficient manner. Most of cooperatives lacks this very important part of action. A five days’ workshop will satisfy the need of this sector by helping to establish and adopt a suitable strategic plan and a framework of budgeting on hand at the end of session.",
            },
            {
              title: "Need",
              text: "Every organization should have a clear vision, mission, objective, strategy & action plan (VMOSA) which is the Guide book to lead the Organization to maximize the benefits and minimize the risk, with this regard SPB will clarify the VMOSA for the organization and clears the most of literally raised issues.",
            },
            {
              title: "Workshop Learning Pedagogy Module of SPB",
              text: "SPB uses a unique methodology for learning fundamentally and technically by using both theoretical and practical aspects. The Theoretical aspects in beginning sessions will enhance the need of chapter and increases the thirst of the matter and the second practical session will fulfill the thirst by the method of learning by doing.",
            },
            {
              title: "Expected Output",
              text: "Able develop a strategy plan for their co-operative and establish a budget for their cooperative. UnderstandthevalueofVMOSA. Run their cooperative in a dynamic manner.",
            },
          ],
        },
        {
          title: "Practical Teachers Training",
          text: "",
          children: [
            {
              title: "Introduction",
              text: `This course has been designed for those academicians who wish to pursue their career in the field of teaching. As teaching is highly respected job with enthusiastic, updated personality, we encourage you to take to learn different techniques of preparation as a teacher which includes preparation, presentation, motivation and psychological treatment of class. Teacher training encompasses two aspects - preparing teaches about "the content" and "the methodology". This is to say, the process of preparing teacher about content i.e., 'what aspect' and the methodology i.e., 'how aspect' is teacher training.`,
            },
            {
              title: "Need",
              text: "Teacher training is important for both experienced and those teachers who are novice to the teaching profession. Teachers must get a hang of innovative pedagogy, interactive assessment techniques and use of differentiation in the classroom to enliven the teaching-learning process. It’s important that teachers be aware of the different kind of learners under their care and ways of dealing with them-that’s where good teacher training comes to play.",
            },
            {
              title: "Workshop Learning Pedagogy Module of SPB",
              text: "Participants will be trained to teach under the guidance of experienced trainers. Participants will be provided simulated platform to deliver the content designed by them on the topics given by the trainers & this process shall be followed continuously till the participants learn the various aspects & methodology of teaching, that the trainer intents to build on the participants.",
            },
            {
              title: "Expected Output",
              text: "Enhanced communication skill, leadership skill & organizing skill. Enhanced teaching technique to keep interest of students at learning. Improved skill on art of teaching to various types of learners. Techniques to increase interaction among teacher and students. Enhanced presentation skill. Enhanced confidence on teaching the subject matter among students.",
            },
          ],
        },
        {
          title: "Professional Accounting Training",
          text: "",
          children: [
            {
              title: "Introduction",
              text: "This course has been designed targeting three layers of management involved in accounting profession namely to “Makers”, “Checkers” & “Verifier” which will be provided by Chartered Accountants, Semi Qualified Chartered Accountant, experienced accountants. It includes basic to comprehensive accounting with an aim of fulfilling the expectation gap that exists between the desired level of performance & delivered level of performance. It focuses on competency building by providing practical assistance & guidance on accounting from accounting entry to financial statement preparation and its analysis. Further, this package includes training on MS- Excel & MS-Word with a view to improve reporting skill of accountants.",
            },
            {
              title: "Need",
              text: "To implement proper accounting practice in an organization. To broaden the accounting knowledge. Toimprovereportingskills. To broaden the knowledge on requirement of Tax & VAT and its impact on accounting. To improve skills to work on MS-Excel & MS-Word. To get job placement assistance (applicable only for maker level training) To develop analytical skill for the analysis the financial statement.",
            },
            {
              title: "Workshop learning pedagogy module of Professional Accounting Training",
              text: "The learning module of training consists of brief theory & detailed practical approach. Trainee will be provided with various cases of transaction & guided to work on such case as if that case has arisen during the job itself. Practical approach will be continued throughout the session so as to boost the confidence level of trainee while dealing with such cases on the job.",
            },
            {
              title: "Expected Output",
              text: "Job placement (only for maker level). Increment in skills of participants. Increment in level of confidence on the job. Increment in knowledge on basic to complex accounting transaction, Income Tax & VAT. FluencyonMS-Excel&MS-Word. FluencyonFinancialStatementpreparation&itsanalysis.",
            },
          ],
        },
        {
          title: "Public Speak Training",
          text: "",
          children: [
            {
              title: "Introduction",
              text: "Public speaking is the act of giving a speech or presentation to a live audience with a goal to inform, persuade, or entertain. This is designed with a motive to eliminate the fears with constant practice on various session conducted by our resource person. We aim to build confidence of participant and improve their skills to enjoy public speaking for any situation as being a good speaker is often critical for leadership positions in the world of business, community groups, government, and other settings.",
            },
            {
              title: "Need",
              text: "To be able to present oneself in front of mass in a better manner. To be able to build perspective on mind of mass. To develop leadership & communication skill. To eliminate fear of public speaking. To develop skill of immediate delivery in any situation with confidence. To learn presentation skills.",
            },
            {
              title: "Workshop learning pedagogy module of Public Speaking Training",
              text: "Public speaking will be practiced by the participants under the guidance of trainers and appropriate step-based process shall be followed to build skill and confidence of trainee. Session based learning under various topics will be adapted to train the participant.",
            },
            {
              title: "Expected output",
              text: "Abilitytodeliverinanysituation. Increment in professionalism. Strengthen oral communication skills. Able to advocate for causes you care about. Learn to be calm in stressful situations. Increased presentation skills.",
            },
          ],
        },
        {
          title: "Proposal Writing workshop",
          text: "",
          children: [
            {
              title: "Introduction",
              text: "Proposal writing is the act of creating a written pitch on a given subject. Good proposal writing requires a lot of forethought, understanding of your audience, and creative writing to persuade the reader. We intent to provide knowledge, to the participant regarding various aspects of a proposal writing that has the ability to create positive impact on the reader direct, direct from the experienced tutor through this course.",
            },
            {
              title: "Need",
              text: "To improve understandability of the service/project that the proposal relates to. To improve presentation skills of various topics to be covered under proposal. To improve the credibility that the proposal depicts. To make improved proposal for setting expectations for client relationship. To make improved proposal that inform better project planning to client.",
            },
            {
              title: "Workshop learning pedagogy module of Proposal Writing",
              text: "Proposal writing will be practiced by the participants under the guidance of experienced trainers. Participant will be given various ideas/topics to work on based on recommendations & instructions of trainers. The work of participants will be closely monitored by our trainers & they will be guided throughout the process to generate an effective & impactful proposal.",
            },
            {
              title: "Expected Output",
              text: "This workshop will prepare you to: Examine the essential parts of any proposal and how to create them. Practice writing selected parts of a grant proposal for your own project. Find potential client for your proposal. Learn what client want in a proposal and the biggest red flags from their perspective. Write winning proposals effectively & efficiently. Think like proposal evaluator.",
            },
          ],
        },
        {
          title: "Co- operative Management Training",
          text: "",
          children: [
            {
              title: "Introduction",
              text: "This training is designed to make cooperatives businesses & its personnel aware of cooperatives values, principles & practices. Cooperative is one of the pillars of economic development as mentioned in “Constitution of Nepal, 2072.” With being the basis for economic development, it is necessary for cooperative to operate its activities within the parameter of regulatory directives & guidelines. This course is aimed at broadening the importance of cooperatives for economic & social welfare of its members & also to broaden the concept of cooperatives so that they can build positive image of cooperatives in public.",
            },
            {
              title: "Need",
              text: "To understand the importance of good governance in Cooperatives. To understand the cooperative norms,values & principles Tounderstanddifferentregulatoryaspectofcooperatives To build the positive prospective of cooperatives. To run cooperative affairs in regulated manner. To increase relations among cooperatives.",
            },
            {
              title: "Workshop learning pedagogy module of Co-operative Management Training",
              text: "In this workshop, participative cooperative will be trained in the above-mentioned content in collaborative & coordinative approach with the integration of technology to make better understanding of the content",
            },
            {
              title: "Expected Output",
              text: "This training will prepare you: To operate cooperatives as per its values, norms, principles & legal provisions. To run activities of saving & credit in proper way. To evaluate organization as per Central Member Information Center. To operate cooperatives with good governance. To make utilization of technology in cooperatives for better outcomes.",
            },
          ],
        },
      ],
    },
    {
      id: 3,
      title: "Internships & Placement",
      link: "https://docs.google.com/forms/d/e/1FAIpQLSdPEyZmIsnF15KKXb2pWhC8BUcnN7cyX6KAVhsbNZH2Gj_QyQ/viewform?usp=pp_url&entry.1163862712=",
      linkText: "Interested In",
      description:
        "After completion of the seminar & technical trainings, interested participants are guided by our teams to approach to various organizations for the internships and placement depending upon the level of their understanding of the subject matter. Further, participant on individual basis will be assisted in improving their skill set to get ready for internships & placements.",
      checkList: [],
    },
  ],
};
