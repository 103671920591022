import React from "react";

const PersonDetails = ({ data }) => {
  const { position, name, email, phone } = data;
  return (
    // <section className="wrapper bg-light">
    // <div className="container py-14 py-md-16">
    <>
      <div className="row text-center">
        <div className="col-xl-12 mx-auto">
          <h2 className="fs-15 text-uppercase text-white mb-3"> {position} </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-lg-12">
          {/* <a href="#" className="card shadow-lg lift h-100"> */}
          <div className="card-body p-0 pb-5 d-flex flex-row">
            <div>
              <span className="avatar bg-red text-white w-11 h-11 fs-20 me-4">SM</span>
            </div>
            <div>
              {/* <span className="badge bg-pale-blue text-blue rounded py-1 mb-2">{name}</span> */}
              <h4 className="mb-1 text-white"> {name} </h4>
              <p className="mb-0 text-white">{email} </p>
              <p className="mb-0 text-white">{phone} </p>
              {/* <p className="mb-0 text-white"> Designation: {position} </p> */}
            </div>
          </div>
          {/* </a> */}
        </div>
      </div>
    </>
    // </div>
    // </section>
  );
};

export default PersonDetails;
